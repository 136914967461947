import { Plan, PlansWOSingle } from '@/types/plan';
import { NewUser, User } from '@/types/user';

export enum SnapTrackingEvent {
  SignUp = 'SIGN_UP',
  AddToCart = 'ADD_CART',
  StartCheckout = 'START_CHECKOUT',
  ViewContent = 'VIEW_CONTENT',
  Purchase = 'PURCHASE',
}

export type SnapTrackingObject = {
  price: number;
  currency: string;
  'item_ids': string[];
  'item_category': string;
  'number_items'?: number;
  'uuid_c1': string;
  'user_email': string;
  // user_phone_number: string,
  firstname: string;
  lastname: string;
  'transaction_id'?: string;
  'customer_status'?: 'new' | 'returning' | 'reactivated';
  success?: 0 | 1;
  'payment_info_available'?: 0 | 1;
};

export function snapTrackInit(email: string) {
  if (window.snaptr) {
    window.snaptr('init', '17ef010d-64c8-482c-a99a-d700a2fe7494', {
      user_email: `${email}`,
    });
  }
}

export function snapTrackSignUp(user: NewUser) {
  if (window.snaptr && user) {
    window.snaptr('track', SnapTrackingEvent.SignUp, {
      sign_up_method: 'Email',
      uuid_c1: user.userId,
      user_email: user.email,
      user_phone_number: user.mobile,
      firstname: user.firstName,
      lastName: user.lastName,
      // geo_country: '',
    });
  }
}

export function snapTrackEvent(
  sessionId: string,
  user: User,
  plan: any,
  chargedAmount: number,
  eventId: SnapTrackingEvent,
  paymentInfoAvailable?: 0 | 1,
  success?: 0 | 1,
) {
  if (window.snaptr && user && plan && chargedAmount && eventId) {
    let trackingObj: SnapTrackingObject = {
      price: chargedAmount,
      currency: plan.currency,
      item_ids: [plan.id],
      item_category: plan.category,
      number_items: 1,
      uuid_c1: user.id,
      user_email: user.email,
      // user_phone_number: user.mobile,
      firstname: user.firstName,
      lastname: user.lastName,
    };

    if (eventId === SnapTrackingEvent.Purchase) {
      trackingObj = {
        ...trackingObj,
        transaction_id: `${user.id}-${sessionId}`,
        success,
      };
    }

    if (eventId === SnapTrackingEvent.StartCheckout) {
      trackingObj = {
        ...trackingObj,
        payment_info_available: paymentInfoAvailable,
      };
    }

    if (eventId === SnapTrackingEvent.ViewContent) {
      delete trackingObj.number_items;
    }

    window.snaptr('track', eventId, trackingObj);
  }
}
