import { Plan, PlansWOSingle } from '@/types/plan';
import { User } from '@/types/user';

export enum XTrackingEvent {
  AddToCart = 'AddToCart', // 'tw-olemh-olqe2'
  CheckoutInitiated = 'CheckoutInitiated', // 'tw-olemh-olqe4'
  AddPaymentInfo = 'AddPaymentInfo', // 'tw-olemh-olqe6'
  ContentView = 'ContentView', // 'tw-olemh-olqe9'
  Purchase = 'Purchase', // 'tw-olemh-oll29'
}

function getEventId(eventName: XTrackingEvent) {
  let eventId;

  switch (eventName) {
    case XTrackingEvent.AddToCart:
      eventId = 'tw-olemh-olqe2';
      break;
    case XTrackingEvent.CheckoutInitiated:
      eventId = 'tw-olemh-olqe4';
      break;
    case XTrackingEvent.AddPaymentInfo:
      eventId = 'tw-olemh-olqe6';
      break;
    case XTrackingEvent.ContentView:
      eventId = 'tw-olemh-olqe9';
      break;
    case XTrackingEvent.Purchase:
      eventId = 'tw-olemh-oll29';
      break;
    default:
      eventId = '';
  }

  return eventId;
}

export function xTrackEvent(sessionId: string, user: User, plan: any, chargedAmount: number, eventName: XTrackingEvent) {
  const eventId = getEventId(eventName);
  if (window.twq && sessionId && user && plan && chargedAmount && eventId) {
    window.twq('event', eventId, {
      value: chargedAmount,
      currency: plan.currency,
      contents: [{
        content_id: plan.id,
        content_name: plan.name,
        content_type: plan.priceId,
        content_price: plan.price,
        num_items: 1,
        content_group_id: plan.category,
      }],
      conversion_id: sessionId,
      email_address: user.email,
      // phone_number: user.mobile,
    });
  }
}
