import { Plan, PlansWOSingle } from '@/types/plan';
import { NewUser, User } from '@/types/user';

export enum MetaTrackingEvent {
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  CompleteRegistration = 'CompleteRegistration',
  InitiateCheckout = 'InitiateCheckout',
  Lead = 'Lead',
  ViewContent = 'ViewContent',
  Purchase = 'Purchase',
}

export type MetaTrackingObject = {
  value: number;
  currency: string;
  contents: { id: string, quantity: number }[];
  'content_type': string;
};

export function metaTrackCompleteRegistration(user: NewUser) {
  if (window.fbq && user) {
    window.fbq('track', MetaTrackingEvent.CompleteRegistration, {
      content_name: 'Registration Form',
      content_category: 'Signup',
      content_ids: [user.userId],
      content_type: 'user_registration',
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
    });
  }
}

export function metaTrackEvent(sessionId: string, user: User, plan: any, chargedAmount: number, eventName: MetaTrackingEvent) {
  if (window.fbq && sessionId && user && plan && chargedAmount && eventName) {
    let trackingObj: MetaTrackingObject | null = null;
    trackingObj = {
      value: chargedAmount,
      currency: plan.currency,
      contents: [{
        id: plan.id,
        quantity: 1,
      }],
      content_type: 'product',
    };
    if (trackingObj) window.fbq('track', eventName, trackingObj);
  } else if (window.fbq && eventName) {
    window.fbq('track', eventName);
  }
}
