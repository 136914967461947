import axios from 'axios';

import {
  connectionTypeJWT,
  customerCodeJWT,
  isExpiredJWT,
  locationIdJWT,
  macJWT,
  nasIdJWT,
} from '@/utils/DecodeJWT';
import { gtmPurchase } from '@/utils/gtmEvents';
import { snapTrackSignUp } from '@/utils/snapchatEvents';
import { metaTrackCompleteRegistration } from '@/utils/metaEvents';

import {
  GET_STARDUST_TOKEN,
  REMOVE_STARDUST_TOKEN,
  SET_STARDUST_TOKEN,
} from '@/store/stardustSession';
import { DEFAULT_AUTH_SERVICE_API_BASE } from '@/store/apiConstants';
import { ACTION_REPLACE_PLANS } from '@/store/plan';

export const ACTION_REQUEST_PASSWORD_RESET_EMAIL = 'global/requestPasswordResetEmail';
export const ACTION_VERIFY_RECAPTCHA_TOKEN = 'global/verifyReCaptchaToken';
export const ACTION_GET_FB_LOGIN_URL = 'global/getFbLoginUrl';
export const ACTION_LOGIN = 'global/login';
export const ACTION_MFA = 'global/mfaLogin';
export const ACTION_RESEND_SMS_CODE = 'global/resendSmsCode';
export const ACTION_LOGOUT = 'global/logout';
export const ACTION_CHECK_COUPON = 'global/checkCoupon';
export const ACTION_FINISH_PURCHASE = 'global/finishPurchase';
export const MUTATION_REPLACE_ERROR = 'global/replaceError';
export const ACTION_BLANK_SESSION = 'global/blankSession';
export const MUTATION_CALL_MADE = 'global/upsertCallMade';

// Must be named globalStore to prevent naming collision with jest
export const globalStore = {
  namespaced: true,
  intercom_enabled: false,
  yieldify_proxy_host: 'localhost',
  state: {
    publicConfig: {},
    error: {
      isErrorShow: false,
      errorMessage: null,
    },
    defaultErrorMessages: {
      cardInvalid: 'There was a problem verifying your card. Please try again or use a different card.',
      paymentFailed: 'There was an issue processing your payment. This could be due to insufficient funds or due to some of the details provided being incorrect. Additionally the issuing bank might require further authentication.',
    },
    //  None of these values can exceed 100, otherwise SQL errors occur
    maxChars: {
      deviceName: 50,
      firstName: 50,
      lastName: 50,
      email: 100,
      phone: 50,
      zoneName: 30,
      description: 500, //  This is ok as it is a value for the bf database
      buildingName: 50,
      siteName: 50,
    },
    requiredRegistrationFieldsForFapu: {},
    radiusNotification: {
      id: null,
      message: '',
    },
    mfa: {
      username: '',
      password: '',
      phoneNumber: '',
      nextCodeCreationTime: 0,
      skipSmsCheck: false,
    },
    changePaymentCallMade: false,
    callMade: false,
  },
  getters: {
    debugMode(state) {
      return !!state.publicConfig && state.publicConfig.UI_DEVELOPMENT_MODE === true;
    },
    stripeEnabled(state) {
      return !!state.publicConfig && state.publicConfig.WIFI_STRIPE_ENABLED === true;
    },
  },
  mutations: {
    upsertCallMade(state, data) {
      state.callMade = data;
    },
    upsertChangePaymentCallMade(state, data) {
      state.changePaymentCallMade = data;
    },
    replacePublicConfig(state, config) {
      state.publicConfig = config;
    },
    replaceError(state, error) {
      state.error.isErrorShow = true;
      state.error.errorMessage = error;
    },
    closeError(state) {
      state.error.isErrorShow = false;
    },
    upsertRequiredRegistrationFieldsForFapu(state, requiredRegistrationFieldsForFapu) {
      state.requiredRegistrationFieldsForFapu = requiredRegistrationFieldsForFapu;
    },
    upsertRadiusNotification(state, radiusNotification) {
      state.radiusNotification = radiusNotification;
    },
    upsertMfaCredentials(state, data) {
      state.mfa.username = data.username;
      state.mfa.password = data.password;
    },
    upsertMfaPhoneNumber(state, phoneNumber) {
      state.mfa.phoneNumber = phoneNumber;
    },
    upsertNextCodeCreationTime(state, nextCodeCreationTime) {
      state.mfa.nextCodeCreationTime = nextCodeCreationTime;
    },
    upsertSkipSmsCheck(state, skipSmsCheck) {
      state.mfa.skipSmsCheck = skipSmsCheck;
    },
  },
  actions: {
    async blankSession({ rootState, dispatch, commit }, locationDetails) {
      commit('plan/removeCoupon', null, { root: true });
      const authApiBase = rootState.global.publicConfig.AUTH_SERVICE_API_BASE ? rootState.global.publicConfig.AUTH_SERVICE_API_BASE : DEFAULT_AUTH_SERVICE_API_BASE;
      if (!locationDetails || !locationDetails.location_id) {
        return null;
      }
      const headersConfig = {
        headers: {
          'Location-Details': JSON.stringify(locationDetails),
        },
      };
      await axios.get(`${authApiBase}/blank-session`, headersConfig)
        .catch((error) => {
          console.error(error);
          window.location.href = '/portal';
        })
        .then(async (response) => {
          const blankSessionToken = response.headers.authorization.split(' ')[1];
          await dispatch(SET_STARDUST_TOKEN, blankSessionToken, { root: true });
          rootState.api.stardust.defaults.headers.common.Authorization = `Bearer ${blankSessionToken}`;
        });
    },
    async login({ rootState, dispatch, commit }, data) {
      if (isExpiredJWT()) {
        await dispatch('blankSession', {
          mac: macJWT(),
          nas_id: nasIdJWT(),
          location_id: locationIdJWT(),
          customer_code: customerCodeJWT(),
          connection_type: connectionTypeJWT(),
        });
      }
      const token = await dispatch(GET_STARDUST_TOKEN, null, { root: true });
      let config;
      if (customerCodeJWT()) {
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'registration-stage': rootState.global.mfa.skipSmsCheck ? 1 : 0,
          },
        };
      } else config = {};
      let payload;
      if (!customerCodeJWT()) {
        payload = {
          username: `${data.email}`,
          password: data.password,
        };
      } else {
        payload = {
          username: `${data.email}@${customerCodeJWT()}`,
          password: data.password,
        };
        commit('upsertMfaCredentials', payload);
      }
      const authApiBase = rootState.global.publicConfig.AUTH_SERVICE_API_BASE ? rootState.global.publicConfig.AUTH_SERVICE_API_BASE : DEFAULT_AUTH_SERVICE_API_BASE;

      return axios.post(`${authApiBase}/login`, payload, config)
        .then(async (response) => {
          if (rootState.siteInfo.mfaViaSms && !rootState.global.mfa.skipSmsCheck) {
            commit('upsertMfaPhoneNumber', response.data.phoneNumber);
          }
          if (!rootState.siteInfo.mfaViaSms || rootState.global.mfa.skipSmsCheck) {
            await dispatch(SET_STARDUST_TOKEN, response.headers.authorization.split(' ')[1], { root: true });
            rootState.api.stardust.defaults.headers.common.Authorization = `${response.headers.authorization}`;
          }
          return {
            success: true,
            errorMessage: '',
          };
        })
        .catch((error) => {
          let message;
          if (error.response.status === 401) {
            if (error.response.headers['www-authenticate'] === 'Bad credentials') {
              message = 'Your e-mail and/or password do not match. Please make sure you are using correct credentials or alternatively try resetting your password.';
            } else if (error.response.headers['www-authenticate'] === 'Your account is still disabled') {
              message = 'Your account has been disabled due to suspicious activity. Try again later.';
            } else if (error.response.headers['www-authenticate'] === 'Unaccepted verification email') {
              message = 'Your account has not been verified. Please verify it by email.';
            } else {
              message = error.response.headers['www-authenticate']
                ? error.response.headers['www-authenticate']
                : 'Your e-mail and/or password do not match. Please make sure you are using correct credentials or alternatively try resetting your password.';
            }
          } else message = error.response.headers['www-authenticate'] ? error.response.headers['www-authenticate'] : '';
          if (message && !rootState.siteInfo.isOutsideNetwork) {
            return {
              success: false,
              errorMessage: message,
            };
          }
          throw new Error(message);
        });
    },

    async mfaLogin({ rootState, dispatch }, data) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('Token')}`,
        },
      };
      const payload = {
        username: rootState.global.mfa.username,
        password: rootState.global.mfa.password,
      };
      const authApiBase = rootState.global.publicConfig.AUTH_SERVICE_API_BASE ? rootState.global.publicConfig.AUTH_SERVICE_API_BASE : DEFAULT_AUTH_SERVICE_API_BASE;
      return axios.post(`${authApiBase}/mfa/check-code/${data.smsCode}`, payload, config)
        .then(async (response) => {
          await dispatch(SET_STARDUST_TOKEN, response.headers.authorization.split(' ')[1], { root: true });
          rootState.api.stardust.defaults.headers.common.Authorization = `${response.headers.authorization}`;
          return {
            success: true,
            errorMessage: '',
          };
        })
        .catch((error) => {
          const message = error.response.headers['www-authenticate'] ? error.response.headers['www-authenticate'] : '';

          if (message) {
            return {
              success: false,
              errorMessage: message,
            };
          }
          throw error;
        });
    },
    activateAccount({ rootState }, data) {
      return rootState.api.stardust.post('user/activate-account', {
        activationCode: data.activationCode,
        password: data.password,
      }).then(response => response.data);
    },
    requestPasswordResetEmail({ rootState }, data) {
      return rootState.api.stardust.post('user/password-reset/request-email', {
        email: data.email,
      });
    },
    resetPassword({ rootState }, data) {
      return rootState.api.stardust.post('user/password-reset/by-email', {
        code: data.reset_code,
        password: data.password,
      });
    },
    verifyReCaptchaToken({ rootState }, reCaptchaToken) {
      return rootState.api.stardust.post(`user/verify-recaptcha-token?response=${reCaptchaToken}`)
        .then(response => response.data);
    },
    getFbLoginUrl({ rootState }) {
      return rootState.api.old.get('/api/fbAuth/getLoginUrl')
        .then(response => response.data.loginUrl);
    },
    async logout({ dispatch, commit, rootState }) {
      commit('plan/removeCoupon', null, { root: true });
      const headersConfig = {
        headers: {
          'Location-Details': JSON.stringify({
            mac: macJWT(),
            nas_id: nasIdJWT(),
            location_id: locationIdJWT(),
            customer_code: customerCodeJWT(),
            connection_type: connectionTypeJWT(),
          }),
        },
      };
      await dispatch(REMOVE_STARDUST_TOKEN, null, { root: true });
      rootState.api.stardust.defaults.headers.common.Authorization = null;
      const authApiBase = rootState.global.publicConfig.AUTH_SERVICE_API_BASE ? rootState.global.publicConfig.AUTH_SERVICE_API_BASE : DEFAULT_AUTH_SERVICE_API_BASE;
      return axios.get(`${authApiBase}/blank-session`, headersConfig)
        .catch((error) => {
          console.error(error);
          window.location.href = '/portal';
        })
        .then(async (response) => {
          await dispatch(SET_STARDUST_TOKEN, response.headers.authorization.split(' ')[1], { root: true });
          rootState.api.stardust.defaults.headers.common.Authorization = `${response.headers.authorization}`;
        })
        .then(() => dispatch('siteInfo/clearAccessCodeData', null, { root: true }))
        .then(() => dispatch('siteInfo/fetchSiteInfo', null, { root: true }))
        .then(() => {
          if (rootState.siteInfo.planEngine !== 'INTERNAL') {
            return dispatch(ACTION_REPLACE_PLANS, null, { root: true });
          }
        });
    },
    register({ rootState, dispatch, commit }, data) {
      return rootState.api.stardust.post('/user', data)
        // registration will not automatically log you in
        // this is so that we can ensure "sites may gate login with a 2FA challenge"
        .then(async (response) => {
          commit('upsertSkipSmsCheck', true);
          await dispatch('login', { email: data.email, password: data.password });
          commit('upsertSkipSmsCheck', false);
          const user = response.data;
          snapTrackSignUp(user);
          metaTrackCompleteRegistration(user);
        })
        .then(() => dispatch('siteInfo/fetchSiteInfo', null, { root: true }));
    },
    registerNoLogin({ rootState }, data) {
      return rootState.api.stardust.post('/user', data).then((response) => {
        const user = response.data;
        snapTrackSignUp(user);
        metaTrackCompleteRegistration(user);
        return response;
      });
    },
    createStripeToken({ rootState }, data) {
      return rootState.api.stripe.createToken(data)
        .then((result) => {
          if (result.error) {
            throw result.error;
          }

          return result;
        });
    },
    cardCapture({ rootState }, stripeToken) {
      return rootState.api.billforward.post('/tokenization/auth-capture', {
        '@type': 'StripeAuthCaptureRequest',
        gateway: 'Stripe',
        accountID: rootState.siteInfo.stripeAccountID,
        stripeToken,
        defaultPaymentMethod: true,
        requiresSetupIntent: true,
      });
    },
    stripeConfirmCardSetup({ rootState }, data) {
      return rootState.api.stripe.confirmCardSetup(data.intentSecret);
    },
    billforwardConfirmCardSetup({ rootState }, paymentMethodId) {
      return rootState.api.stardust.post(`/${rootState.siteInfo.customerCode}/3ds-confirm/${paymentMethodId}`)
        .then(response => response.data);
    },
    attemptQuarantine({ rootState }) {
      if (process.env.NODE_ENV !== 'development') {
        return rootState.api.old.post(`${this.state.global.publicConfig.ROUTER_URL}/login?username=${this.state.global.publicConfig.QUARANTINE_USERNAME}&password=${this.state.global.publicConfig.QUARANTINE_PASSWORD}`);
      }
    },
    removeFromQuarantine({ rootState, commit }) {
      if (process.env.NODE_ENV !== 'development') {
        // set callMade to true before call to prevent it to trigger more than once
        commit('upsertCallMade', true);
        return rootState.api.old.post(`${this.state.global.publicConfig.ROUTER_URL}/logout`);
      }
    },
    // TODO remove after stripe migration
    finishPurchase({ rootState }, receiptID) {
      return rootState.api.stardust.post(`/subscription/finish/${receiptID}`);
    },
    internalSubscription({ rootState }) {
      return rootState.api.stardust.post('/internal-subscription');
    },
    purchase({ commit, rootGetters, rootState }) {
      const currentPlan = rootGetters['plan/selectedPlan'];
      const { devices, speed } = rootState.plan.planOptions;
      let quantities = {};

      if (currentPlan.legacyPricing) {
        quantities = {
          Devices: devices || currentPlan.baseDevices,
          Speed: speed || currentPlan.baseSpeedMbps,
        };
      } else {
        quantities = {
          'Base Package': 1,
        };
      }

      const payload = {
        couponCode: rootState.plan.coupon.value,
        planId: currentPlan.productRatePlanId,
        quantities,
      };

      return rootState.api.stardust.post('/subscription', payload)
        .then((response) => {
          const data = response.data;
          if (data.id) {
            const subscriptionId = data.id;
            const invoiceId = data.invoiceId;
            const quoteTotal = rootState.plan.cost.totalCost;
            const plan = data.plan;
            gtmPurchase(subscriptionId, invoiceId, quoteTotal, plan);
          }

          commit('plan/setPlanFinalData', response.data, { root: true });
        });
    },
    quote({ commit, rootGetters, rootState }) {
      const currentPlan = rootGetters['plan/selectedPlan'];
      const tierMax = currentPlan.tierMax;
      const { devices, speed } = rootState.plan.planOptions;

      let quantities = {};

      if (currentPlan.legacyPricing) {
        quantities = {
          Devices: devices || currentPlan.baseDevices,
          Speed: speed || currentPlan.baseSpeedMbps,
        };
      } else {
        quantities = {
          'Base Package': 1,
        };
      }
      // TODO remove after figure out where to get cost
      // return rootState.api.stardust.post('/plans/quote', {
      //   coupon: rootState.plan.coupon.value,
      //   planId: currentPlan.productRatePlanId,
      //   pricingComponentValues: quantities,
      // }).then((response) => {
      //   commit('plan/setCost', response.data, { root: true });
      //   commit('plan/setDiscount', response.data, { root: true });
      // });
    },
    checkCoupon({ rootState }, coupon) {
      return rootState.api.stardust.get(`/coupon/validate/${coupon}`).then(response => response.data);
    },
    fetchPublicConfig({ commit }) {
      return axios.get('/v2/public-config.json')
        .then((response) => {
          commit('replacePublicConfig', response.data);

          return response;
        }, (err) => {
          commit('global/replaceError', err, { root: true });
          throw err;
        });
    },
    getChatData({ rootState }) {
      return rootState.api.stardust.get('/live-agent')
        .then(response => response.data);
    },
    fetchRequiredRegistrationFields({ commit, rootState }) {
      return rootState.api.stardust.get('/site-info/fapu-properties')
        .then((response) => {
          commit('upsertRequiredRegistrationFieldsForFapu', response.data.properties);
          return response.data;
        });
    },
    getRadiusNotification({ commit, rootState }) {
      let url = `/devices/notification?customerCode=${customerCodeJWT()}`;
      const mac = macJWT();
      if (mac !== 'null' && mac !== null && mac !== 'IGNORE_MAC') url += `&mac=${mac}`;
      return rootState.api.stardust.get(url)
        .then((response) => {
          commit('upsertRadiusNotification', response.data);
          return response.data;
        }, (err) => {
          commit('global/replaceError', err, { root: true });
          throw err;
        });
    },
    disableRadiusNotification({ commit, rootState }, id) {
      return rootState.api.stardust.put('/devices/notification', {
        id,
      })
        .then((response) => {
          commit('upsertRadiusNotification', { id: null, message: '' });
          return response.data;
        }, (err) => {
          commit('global/replaceError', err, { root: true });
          throw err;
        });
    },
    postSpeedTestResult({ rootState }, data) {
      return rootState.api.stardust.post('/speed-test', data).then(response => response);
    },
    async resendSmsCode({ commit, rootState }) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('Token')}`,
        },
      };
      const payload = {
        username: rootState.global.mfa.username,
        password: rootState.global.mfa.password,
      };
      const authApiBase = rootState.global.publicConfig.AUTH_SERVICE_API_BASE ? rootState.global.publicConfig.AUTH_SERVICE_API_BASE : DEFAULT_AUTH_SERVICE_API_BASE;
      return axios.post(`${authApiBase}/mfa/resend-code`, payload, config)
        .then((response) => {
          commit('upsertNextCodeCreationTime', response.data.nextCodeCreationTime);
        })
        .catch((error) => {
          const message = error.response.headers['www-authenticate'] || 'Something went wrong';
          if (message) {
            return {
              success: false,
              errorMessage: message,
            };
          }
          throw error;
        });
    },
  },
};
